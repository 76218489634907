<template lang="html">
  <div id="app">
    <div v-if="$fid.env!=='production'" :class="$fid.env" style="background: red; z-index: 99999; width: 100%; text-align: center; color: #ffffff; padding: 3px 0; opacity: 0.7; pointer-events: none;">
      This is {{$fid.env}} server
    </div>
    <div class="sidebar" v-if="loaded && !$route.meta.fullpage">
      <sidebar v-if="state.current_user.permissions && (state.current_user.permissions.full_access || state.current_user.permissions.support_access || state.current_user.permissions.super_admin_full_access)"  />
      <subscription_access_sidebar v-else />
    </div>

    <main>
      <div class="container clearfix">
        <router-view :key="$route.fullPath" v-if="loaded" />
      </div>
    </main>
  </div>
</template>

<script>
import sidebar from 'templates/shared/_sidebar'
import subscription_access_sidebar from 'templates/shared/_subscription_access_sidebar'
export default {
  components: {sidebar,subscription_access_sidebar},
  data(){
    return {
      loaded: false,
    }
  },
  async created (){
    const user = await this.$store.dispatch("login")
    this.loaded = true

    if (!user.id) this.$router.push("/signin")
    else if (this.$route.path.startsWith("/signin")) this.$router.push("/")
  },
}
</script>
