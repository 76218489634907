import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'balloon-css'
import 'flatpickr/dist/flatpickr.css';
import 'css/layouts'
import 'font-awesome/scss/font-awesome.scss'

import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Qs from 'qs'

axios.defaults.paramsSerializer= (params)=>Qs.stringify(params, {arrayFormat: 'brackets'})
Vue.use(VueAxios, axios)
const allowedTags = "em,p,u,i,strong,ol,ul,li,a,br,strong,b,strike,table,thead,tr,td,th,tbody,blockquote,h1,h2,h3,h4,h5,h6,pre".split(",")
import VueSanitize from "vue-sanitize"
Vue.use(VueSanitize, {allowedTags: allowedTags});
import {store} from "../code/store.js";
import {router} from "../code/routes.js";
import base_mixin from "../code/base_mixins.js"
Vue.mixin(base_mixin)

import App from '../app.vue'
import 'filters/currency.js'


function loadVue() {
  let el = document.querySelector("#vue-app")
  if (!el) return

  let node = document.querySelector("meta[name='csrf-token']");
  if (node) {
    axios.defaults.headers.post['X-CSRF-Token'] = node.content
    axios.defaults.headers.patch['X-CSRF-Token'] = node.content
  }

  Vue.prototype.$fid = {};
  for (let k of Object.keys(el.dataset)) {
    Vue.prototype.$fid[k] = el.dataset[k];
  }


  const app = new Vue({
    store,
    router,
    el,
    render: h => h(App)
  })
  window.app = app
}

document.addEventListener('DOMContentLoaded', loadVue)
document.addEventListener('page:load', loadVue)
