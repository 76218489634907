import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";

// import {Registration} from './../models/registration.js'
// import {Organization} from './../models/organization.js'

Vue.use(Vuex)

//https://www.youtube.com/watch?v=iw1eajzWQAM
// using ...
// check mapGetters
//to watch https://www.youtube.com/watch?v=dkFWOsKrPAI
export const store = new Vuex.Store({
    state: { // keyword to set variables
      current_user: {organizations: []},
      counter: Date.now(),
    },
    actions: {
        async login ({state}){
          const id    = window.localStorage.getItem("fid_id")
          const token = window.localStorage.getItem("fid_token")
          axios.defaults.headers.common['Access-Token'] = token

          try {
            state.current_user = (await Vue.axios.get(`/api2/admin/users/${id}`, {params: {include: ['permissions']}})).data.object;
            if (!state.current_user.permissions.admin_login){
              return state.current_user = {}
            }

            const res = await axios.get(`/api2/admin/organizations`,
              {
                params: {
                  per: 1
                }
              })
            state.current_user = {...state.current_user}
          }catch (e) {
            state.current_user = {}
          }finally {
            return state.current_user
          }
        },
        logout ({state}) {
            // mutate state
            state.current_user = {organizations: []}
            window.localStorage.removeItem("fid_id")
            window.localStorage.removeItem("fid_token")
            window.localStorage.removeItem('time_zone_name')
        },

    }
})
