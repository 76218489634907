import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

function loadView(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `templates/${view}.vue`)
}

const routes = [
  {path: "/signin", component: loadView('auth/signin'), meta: {fullpage: true}},
  {path: "/",              component: loadView('users/index')},

  {path: "/users",              component: loadView('users/index')},
  {path: "/users/admins_index", component: loadView('users/admins')},
  {path: "/users/:id",          component: loadView('users/show')},
  {path: "/people",             component: loadView('people/index')},
  {path: "/organizations",  component: loadView('organizations/index')},
  {path: "/organizations/new", component: loadView('organizations/edit')},
  {path: "/organizations/account_management", component: loadView('organizations/account_management')},
  {path: "/organizations/uniq_participants", component: loadView('organizations/uniq_participants')},
  {path: "/organizations/:id", component: loadView('organizations/show')},
  {path: "/organizations/:id/edit", component: loadView('organizations/edit')},
  {path: "/organization_accounts",          component: loadView('accounts/index')},
  {path: "/organization_accounts/new",      component: loadView('accounts/edit')},
  {path: "/organization_accounts/:id/edit", component: loadView('accounts/edit')},
  {path: "/reports",            component: loadView('reports/index')},
  {path: "/subscriptions", component: loadView('subscriptions/index')},
  {path: "/subscription_plans",                 component: loadView('subscription_plans/index')},
  {path: "/subscription_plans/new",             component: loadView('subscription_plans/edit')},
  {path: "/subscription_plans/:id/edit",        component: loadView('subscription_plans/edit')},
  {path: "/subscription_plans/:id/rules",       component: loadView('subscription_plans/rules')},
  {path: "/subscription_plans/:id/discounts",   component: loadView('subscription_plans/discounts')},
  {path: "/subscription_plans/:id/plan_addons", component: loadView('subscription_plans/plan_addons')},
  {path: "/email_previews",     component: loadView('email_previews/index')},
  {path: "/email_previews/:id", component: loadView('email_previews/show')},
  {path: "/blocked_emails", component: loadView('blocked_emails/index')},
  {path: "/trials", component: loadView('trials/index')},
  {path: "/trials/new", component: loadView('trials/edit')},
  {path: "/trials/:id", component: loadView('trials/show')},
  {path: "/trials/:id/edit", component: loadView('trials/edit')},
  {path: "/programs",          component: loadView('programs/index')},
  {path: "/programs/:id",      component: loadView('programs/show')},
  {path: "/programs/:id/edit", component: loadView('programs/edit')},
  {path: "/registrations",          component: loadView('registrations/index')},
  {path: "/registrations/:id",      component: loadView('registrations/show')},
  {path: "/registrations/:id/edit", component: loadView('registrations/edit')},
  {path: "/registrations/:id/cart_transactions", component: loadView('registrations/cart_transactions')},
  {path: "/templates", component: loadView('templates/index')},
  {path: "/templates/new", component: loadView('templates/edit')},
  {path: "/templates/:id/edit", component: loadView('templates/edit')},
  {path: "/templates/:id/basic", component: loadView('templates/basic')},
  {path: "/templates/:id/sections", component: loadView('templates/sections')},
  {path: "/templates/:id/addons", component: loadView('templates/addons')},
  {path: "/templates/:id/participant", component: loadView('templates/participant')},
  {path: "/templates/:id/agreements_policies", component: loadView('templates/agreements_policies')},
  {path: "/templates/:id/reports", component: loadView('templates/reports')},
  {path: "/templates/:id/internal_fields", component: loadView('templates/internal_fields')},
  {path: "/settings", component: loadView('settings/index')},
]

export const router = new VueRouter({
    mode: 'history',routes, // short for `routes: routes`
    scrollBehavior (to, from, savedPosition) {
        // return desired position
        if (savedPosition == null){
            window.scrollTo(0, 0);
        }
    },
})
//
// router.beforeEach((to, from, next) => {
//   if (!to.matched[0]) window.location=to.fullPath
//   else next()
// })
